import USAMap from "./USAMap";
import Meets from "./Meets";
import getFullStateName from "../helpers/getFullStateName";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";

function Map() {
  //TODO: Slight animation when mouse hovers over a state; also, change cursor back to pointer
  const [selectedStates, setSelectedStates] = useState([]);

  //if the user's never visited the site before, we create the localStorage array so it can be passed as an empty array into later functions

  useEffect(() => {
    let currLocalStorage = JSON.parse(localStorage.getItem("selectedStates"));
    setSelectedStates(currLocalStorage);
  }, []);

  function clickHandler(e) {
    let path = e.target;

    //#1a2b47 is the blue color, #c3c3c3 is the gray color
    if (path.getAttribute("fill") !== "#1a2b47") {
      path.setAttribute("fill", "#1a2b47");

      let newArray = [...selectedStates];
      newArray.push(path.dataset.name);
      setSelectedStates(newArray); //components don't re-render upon mutation of an already existing array; thus, we must create a new array and replace the old one

      let currLocalStorage = JSON.parse(localStorage.getItem("selectedStates"));
      currLocalStorage.push(path.dataset.name);
      localStorage.setItem("selectedStates", JSON.stringify(currLocalStorage));
    } else {
      path.setAttribute("fill", "#cccccc");

      let index = selectedStates.indexOf(path.dataset.name);
      if (index !== -1) {
        let newArray = [...selectedStates];
        newArray.splice(index, 1);
        setSelectedStates(newArray);

        let currLocalStorage = JSON.parse(
          localStorage.getItem("selectedStates")
        );
        currLocalStorage.splice(index, 1);
        localStorage.setItem(
          "selectedStates",
          JSON.stringify(currLocalStorage)
        );
      }
    }
  }

  function stateNames() {
    return selectedStates.map((e) => getFullStateName(e)).join(", ");
  }

  return (
    <Container fluid>
      <USAMap onClick={clickHandler} defaultFill="#cccccc" />
      <br />
      <p
        style={{
          textAlign: "left",
          display: "block",
          marginLeft: 5 + "px",
          marginBottom: -35 + "px",
          marginRight: 10 + "px",
          width: "48%",
        }}
      >
        Selected States: {selectedStates.length <= 0 ? "All" : stateNames()}
      </p>
      <Meets
        selectedStates={
          localStorage.getItem("selectedStates") !== "[]"
            ? JSON.parse(localStorage.getItem("selectedStates"))
            : selectedStates
        }
      />
    </Container>
  );
}

export default Map;
