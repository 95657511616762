import ClipLoader from "react-spinners/ClipLoader";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "../axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import getFullStateName from "../helpers/getFullStateName";

import APF from "../assets/APF.png";
import OPW from "../assets/OPW.png";
import PA from "../assets/PA.png";
import RPS from "../assets/RPS.png";
import SPF from "../assets/SPF.png";
import USAPL from "../assets/USAPL.png";
import USPA from "../assets/USPA.png";
import WRPF from "../assets/WRPF.png";

export default function Meet() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Content-Type": "application/json",
        },
      };

      const request = await axios.get(`/events?id=${id}`, config);
      const now = new Date();

      let e = request.data.events[0];

      let event = {
        id: e.id,
        eventName: e.evt_name.replaceAll("&#8217;", "'"),
        fed: e.fed.toUpperCase(),
        parsedDate:
          e.parsed_date.substring(8, 11) +
          " " +
          e.parsed_date.substring(5, 7) +
          " " +
          e.parsed_date.substring(12, 16),
        dateAdded: e.created,
        state: e.state,
        link: e.link,
        weeksOut:
          Math.round(
            (10 * (new Date(e.parsed_date).getTime() - now.getTime())) /
              604800000.0
          ) / 10,
      };

      setData(event);
      setIsLoading(false);
    }
    fetchData();
    console.log(data);
  }, []);

  function getImage(fed) {
    switch (fed) {
      case "APF":
        return APF;
      case "OPW":
        return OPW;
      case "PA":
        return PA;
      case "RPS":
        return RPS;
      case "SPF":
        return SPF;
      case "USAPL":
        return USAPL;
      case "USPA":
        return USPA;
      case "WRPF":
        return WRPF;
    }
  }

  function getFed(fed) {
    switch (fed) {
      case "APF":
        return "American Powerlifting Federation";
      case "OPW":
        return "100% RAW";
      case "PA":
        return "Powerlifting America";
      case "RPS":
        return "Revolution Powerlifting Syndicate";
      case "SPF":
        return "Southern Powerlifting Federation";
      case "USAPL":
        return "USA Powerlifting";
      case "USPA":
        return "United States Powerlifting Association";
      case "WRPF":
        return "World Raw Powerlifting Federation";
    }
  }

  return (
    <Container fluid>
      {isLoading ? (
        <div className="loading-wrapper">
          <ClipLoader />
        </div>
      ) : (
        <div>
          <Row className="justify-content-center text-center mx-auto">
            <Col auto>
              <h1
                style={{
                  marginTop: 30 + "px",
                }}
              >
                {data.eventName}
              </h1>
              <Image
                src={getImage(data.fed)}
                style={{ maxWidth: 70 + "%", marginTop: 2 + "%" }}
              />
            </Col>
          </Row>

          <div
            style={{
              maxWidth: 25 + "rem",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 2 + "%",
            }}
          >
            <Row className="justify-content-center mx-auto">
              <Col auto style={{ padding: 0, margin: 0 }}>
                <p>
                  <strong>Federation: </strong>
                </p>
              </Col>
              <Col auto style={{ padding: 0, margin: 0, textAlign: "end" }}>
                <p>{getFed(data.fed)}</p>
              </Col>
            </Row>

            <Row className="justify-content-center mx-auto">
              <Col auto style={{ padding: 0, margin: 0 }}>
                <p>
                  <strong>Date of Meet: </strong>
                </p>
              </Col>
              <Col auto style={{ padding: 0, margin: 0, textAlign: "end" }}>
                <p>{data.parsedDate}</p>
              </Col>
            </Row>

            <Row className="justify-content-center mx-auto">
              <Col auto style={{ padding: 0, margin: 0 }}>
                <p>
                  <strong>Weeks Out: </strong>
                </p>
              </Col>
              <Col auto style={{ padding: 0, margin: 0, textAlign: "end" }}>
                <p>{data.weeksOut}</p>
              </Col>
            </Row>

            <Row className="justify-content-center mx-auto">
              <Col auto style={{ padding: 0, margin: 0 }}>
                <p>
                  <strong>State: </strong>
                </p>
              </Col>
              <Col auto style={{ padding: 0, margin: 0, textAlign: "end" }}>
                <p>{getFullStateName(data.state)}</p>
              </Col>
            </Row>

            <Row>
              <Col auto className="text-center">
                <a
                  className="btn btn-primary"
                  href={data.link}
                  target="_blank"
                  style={{
                    width: 100 + "%",
                  }}
                >
                  Register
                </a>
              </Col>
            </Row>

            <Row style={{ marginTop: 3 + "px", marginBottom: 0.5 + "rem" }}>
              <Col auto className="text-center">
                <Button
                  ref={target}
                  variant="secondary"
                  style={{ width: 100 + "%" }}
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    setShow(!show);
                    setTimeout(setShow(!show), 1000);
                  }}
                >
                  Share
                </Button>
                <Overlay target={target.current} show={show} placement="bottom">
                  {(props) => (
                    <Tooltip {...props}>
                      Copied PowerMeet URL to clipboard!
                    </Tooltip>
                  )}
                </Overlay>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Container>
  );
}
