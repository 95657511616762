import Meets from "./Meets";
import Container from "react-bootstrap/Container";

export default function New() {
  return (
    <Container fluid className="text-center">
      <h1 style={{ marginTop: 30 + "px" }}>New and Recently Updated</h1>
      <p>
        This page shows all meets added or modified within our database in the
        last 3 days.
      </p>
      <Meets newEventFilter={true} selectedStates="" gridHeight={"65vh"} />
    </Container>
  );
}
