export default function getFullStateName(stateAbbreviation){
    let fullStateName;

    switch(stateAbbreviation){
        case 'AZ': fullStateName = 'Arizona'; break;
        case 'AL': fullStateName = 'Alabama'; break;
        case 'AK': fullStateName = 'Alaska'; break;
        case 'AR': fullStateName = 'Arkansas'; break;
        case 'CA': fullStateName = 'California'; break;
        case 'CO': fullStateName = 'Colorado'; break;
        case 'CT': fullStateName = 'Connecticut'; break;
        case 'DC': fullStateName = 'District of Columbia'; break;
        case 'DE': fullStateName = 'Delaware'; break;
        case 'FL': fullStateName = 'Florida'; break;
        case 'GA': fullStateName = 'Georgia'; break;
        case 'HI': fullStateName = 'Hawaii'; break;
        case 'ID': fullStateName = 'Idaho'; break;
        case 'IL': fullStateName = 'Illinois'; break;
        case 'IN': fullStateName = 'Indiana'; break;
        case 'IA': fullStateName = 'Iowa'; break;
        case 'KS': fullStateName = 'Kansas'; break;
        case 'KY': fullStateName = 'Kentucky'; break;
        case 'LA': fullStateName = 'Louisiana'; break;
        case 'ME': fullStateName = 'Maine'; break;
        case 'MD': fullStateName = 'Maryland'; break;
        case 'MA': fullStateName = 'Massachusetts'; break;
        case 'MI': fullStateName = 'Michigan'; break;
        case 'MN': fullStateName = 'Minnesota'; break;
        case 'MS': fullStateName = 'Mississippi'; break;
        case 'MO': fullStateName = 'Missouri'; break;
        case 'MT': fullStateName = 'Montana'; break;
        case 'NE': fullStateName = 'Nebraska'; break;
        case 'NV': fullStateName = 'Nevada'; break;
        case 'NH': fullStateName = 'New Hampshire'; break;
        case 'NJ': fullStateName = 'New Jersey'; break;
        case 'NM': fullStateName = 'New Mexico'; break;
        case 'NY': fullStateName = 'New York'; break;
        case 'NC': fullStateName = 'North Carolina'; break;
        case 'ND': fullStateName = 'North Dakota'; break;
        case 'OH': fullStateName = 'Ohio'; break;
        case 'OK': fullStateName = 'Oklahoma'; break;
        case 'OR': fullStateName = 'Oregon'; break;
        case 'PA': fullStateName = 'Pennsylvania'; break;
        case 'RI': fullStateName = 'Rhode Island'; break;
        case 'SC': fullStateName = 'South Carolina'; break;
        case 'SD': fullStateName = 'South Dakota'; break;
        case 'TN': fullStateName = 'Tennessee'; break;
        case 'TX': fullStateName = 'Texas'; break;
        case 'UT': fullStateName = 'Utah'; break;
        case 'VT': fullStateName = 'Vermont'; break;
        case 'VA': fullStateName = 'Virginia'; break;
        case 'WA': fullStateName = 'Washington'; break;
        case 'WV': fullStateName = 'West Virginia'; break;
        case 'WI': fullStateName = 'Wisconsin'; break;
        case 'WY': fullStateName = 'Wyoming'; break;
        default: fullStateName = undefined;
    }

    return fullStateName;
}