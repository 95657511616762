import PMNav from "./components/Navbar";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import Federations from "./components/Federations";
import New from "./components/New";
import Meet from "./components/Meet";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  if (!localStorage.getItem("selectedStates")) {
    localStorage.setItem("selectedStates", "[]");
  }

  return (
    <div className="App">
      <PMNav />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/federations" element={<Federations />} />
        <Route path="/new" element={<New />} />
        <Route path="/meet/:id" element={<Meet />} />
      </Routes>
    </div>
  );
}

export default App;
