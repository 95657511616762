//converts date format of MMM DD YYYY to YYYYMMDD
//eg: Feb 14 2023 becomes 20230214

export default function dateToIso(oldDate){

    if (oldDate === undefined || oldDate === null || oldDate.length !== 11){
        return null;
    }

    let year = oldDate.substring(7,11)
    let date = oldDate.substring(4,6)
    let month = monthToIso(oldDate.substring(0,3))

    let result = year + month + date;
    return result;
}

function monthToIso(month){

    switch(month){
        case 'Jan': return '01';
        case 'Feb': return '02';
        case 'Mar': return '03';
        case 'Apr': return '04';
        case 'May': return '05';
        case 'Jun': return '06';
        case 'Jul': return '07';
        case 'Aug': return '08';
        case 'Sep': return '09';
        case 'Oct': return '10';
        case 'Nov': return '11';
        case 'Dec': return '12';
        default: return undefined;
    }
}