import Map from "./Map";
import Container from "react-bootstrap/Container";

function Homepage(props) {
  return (
    <Container fluid className="text-center">
      <h1 style={{ marginTop: 30 + "px" }}>Upcoming Meets</h1>
      <p>
        Select states on the map to filter the table, or use the filter box.
      </p>
      <Map />
    </Container>
  );
}

export default Homepage;
