import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import SPF from "../assets/SPF.png";
import USAPL from "../assets/USAPL.png";
import PA from "../assets/PA.png";
import RPS from "../assets/RPS.png";
import WRPF from "../assets/WRPF.png";
import APF from "../assets/APF.png";
import USPA from "../assets/USPA.png";
import OPW from "../assets/OPW.png";

function Federations() {
  return (
    <Container fluid className="text-center">
      <h1 style={{ marginTop: 30 + "px" }}>Federations</h1>
      <p>PowerMeet currently scrapes data from the following federations:</p>
      <Row className="align-items-center justify-content-center mx-auto">
        <Col xs={6} sm={6} md={3} xxl={3}>
          <a
            href="https://www.usapowerlifting.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Image id="USAPL" alt="USA Powerlifting logo" src={USAPL} fluid />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} xxl={3}>
          <a
            href="https://www.powerlifting-america.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Image id="PA" alt="Powerlifting America logo" src={PA} fluid />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} xxl={3}>
          <a
            href="https://www.revolutionpowerlifting.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              id="RPS"
              alt="Revolution Powerlifting logo"
              src={RPS}
              fluid
            />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} xxl={3}>
          <a href="https://www.thewrpf.com/" target="_blank" rel="noreferrer">
            <Image id="WRPF" alt="WRPF logo" src={WRPF} fluid />
          </a>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mx-auto">
        <Col xs={6} sm={6} md={3} xxl={3}>
          <a
            href="https://worldpowerliftingcongress.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Image id="APF" alt="APF logo" src={APF} fluid />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} xxl={3}>
          <a href="https://uspa.net/" target="_blank" rel="noreferrer">
            <Image
              id="USPA"
              alt="US Powerlifting Association logo"
              src={USPA}
              fluid
            />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} xxl={3}>
          <a
            href="http://www.southernpowerlifting.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Image id="SPF" alt="Southern Powerlifting logo" src={SPF} fluid />
          </a>
        </Col>
        <Col xs={6} sm={6} md={3} xxl={3}>
          <a
            href="https://rawpowerlifting.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Image id="OPW" alt="OPW Logo" src={OPW} fluid />
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Federations;
