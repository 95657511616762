import { useEffect, useState, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import axios from "../axios";
import dateToIso from "../helpers/dateToIso";
import getFullStateName from "../helpers/getFullStateName";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

function Meets(props) {
  const gridRef = useRef(); //used for accessing ag-grid's API

  //rowData re-renders the grid when the array changes (note: it must be a NEW array, so things like array.push() which mutate the array directly will not trigger a re-render)
  const [rowData, setRowData] = useState();
  const [apiData, setApiData] = useState(); //this should only be set once upon API call

  //Setup for ag-grid formatting
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Meet Name",
      wrapText: true,
      autoHeight: true,
      field: "eventName",
      sortable: true,
      resizable: true,
      suppressMovable: true,
      cellStyle: {
        paddingLeft: 10 + "px",
        paddingRight: 3 + "px",
        wordBreak: "normal",
      },
      minWidth: 150,
      flex: 1,
      cellRenderer: function (params) {
        return (
          <Link className="table-link" to={`/meet/${params.data.id}`}>
            {params.value}
          </Link>
        );
      },
    },
    {
      headerName: "Fed",
      field: "fed",
      minWidth: 10,
      maxWidth: 100,
      sortable: true,
      resizable: true,
      suppressMovable: true,
      cellStyle: { paddingLeft: 10 + "px", paddingRight: 3 + "px" },
    },
    {
      headerName: "Date",
      field: "parsedDate",
      wrapText: true,
      minWidth: 15,
      maxWidth: 150,
      sortable: true,
      resizable: true,
      comparator: dateComparator,
      sort: "asc",
      suppressMovable: true,
      cellStyle: {
        paddingLeft: 10 + "px",
        paddingRight: 3 + "px",
        wordBreak: "normal",
      },
    },
    {
      headerName: "Weeks Out",
      field: "weeksOut",
      minWidth: 10,
      maxWidth: 100,
      sortable: true,
      resizable: true,
      suppressMovable: true,
      cellStyle: { paddingLeft: 10 + "px", paddingRight: 3 + "px" },
      hide: window.outerWidth < 500,
    }, // todo: add to on resize logic if and when we get some
    {
      headerName: "State",
      field: "state",
      minWidth: 70,
      maxWidth: 75,
      sortable: true,
      resizable: true,
      suppressMovable: true,
      cellStyle: { paddingLeft: 10 + "px", paddingRight: 0 + "px" },
    },
    { headerName: "Full State", field: "fullStateName", hide: true },
  ]);

  // convert date to YYYYMMDD, then sort by date
  function dateComparator(date1, date2) {
    let date1Number = dateToIso(date1);
    let date2Number = dateToIso(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  //automatically resize columns in grid upon loading
  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit({});
  }, []);

  //quick filter
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);

  //PowerMeet API GET Request, then formatting for ease of access to objects in ag-grid
  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Content-Type": "application/json",
        },
      };

      const request = await axios.get("/events", config);
      const now = new Date();

      let data = request.data.events.map((e) => {
        let newEvent = {
          id: e.id,
          eventName: e.evt_name.replaceAll("&#8217;", "'"),
          fed: e.fed.toUpperCase(),
          parsedDate:
            e.parsed_date.substring(8, 11) +
            " " +
            e.parsed_date.substring(5, 7) +
            " " +
            e.parsed_date.substring(12, 16),
          dateAdded: e.created,
          state: e.state,
          link: e.link,
          weeksOut:
            Math.round(
              (10 * (new Date(e.parsed_date).getTime() - now.getTime())) /
                604800000.0
            ) / 10,
          fullStateName: getFullStateName(e.state),
        };
        return newEvent;
      });

      //filter out any meets that have already happened & store it in our state variable
      data = data.filter((meet) => meet.weeksOut > 0);
      setApiData(data);

      // now we filter based on states stored in localStorage for the first render
      if (props.selectedStates.length !== 0) {
        data = data.filter((meet) => props.selectedStates.includes(meet.state));
      }

      if (props.newEventFilter) {
        //86400000 = milliseconds in a day
        data = data.filter(
          (meet) =>
            now.getTime() - new Date(meet.dateAdded).getTime() < 86400000 * 3
        );
      }

      setRowData(data);
      console.log("API data loaded!");
      return;
    }
    fetchData();
  }, []);

  //re-render table when the props.selectedStates from the Map component is changed
  useEffect(() => {
    try {
      if (props.selectedStates.length === 0) {
        setRowData([...apiData]);
      } else {
        let oldData = [...apiData];
        let newData = oldData.filter(function (row) {
          return props.selectedStates.indexOf(row.state) !== -1;
        });
        setRowData(newData);
      }
    } catch (TypeError) {
      console.log("Loading API data...");
    }
  }, [props.selectedStates]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <input
          type="text"
          id="filter-text-box"
          placeholder="Filter"
          className="filter-text-box"
          onInput={onFilterTextBoxChanged}
          style={{ alignSelf: "flex-end" }}
        ></input>
      </div>
      <div
        className="grid-wrapper ag-theme-alpine"
        style={{ height: `${props.gridHeight}` }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={50}
          domLayout="autoHeight"
          onFirstDataRendered={onFirstDataRendered}
          cacheQuickFilter={true}
          alwaysMultiSort={true}
        />
      </div>
    </div>
  );
}

export default Meets;
