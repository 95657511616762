import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { useState } from "react";
import PMLogo from "../assets/powermeet/PM_logo_white.svg";

function PMNav() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      style={{ backgroundColor: "#1A2B47" }}
      variant="dark"
      expand="sm"
      expanded={expanded}
    >
      <Container
        fluid
        style={{ paddingLeft: 1 + "rem", paddingRight: 1 + "rem" }}
      >
        <LinkContainer to="/">
          <Navbar.Brand>
            <img src={PMLogo} />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/" onClick={() => setExpanded(false)}>
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/new" onClick={() => setExpanded(false)}>
              <Nav.Link>New</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/federations" onClick={() => setExpanded(false)}>
              <Nav.Link>Federations</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default PMNav;
